import 'promise-polyfill/src/polyfill';
import objectFitImages from 'object-fit-images';

import { disableBodyScroll, enableBodyScroll }            from 'body-scroll-lock';
import lozad                                              from 'lozad';
import * as VueGoogleMaps                                 from 'vue2-google-maps';
import GmapCluster                                        from 'vue2-google-maps/dist/components/cluster';
import Swiper, { Navigation, Pagination, Autoplay, Lazy } from 'swiper';
import SlideUpDown                                        from 'vue-slide-up-down';
import lottie                                             from 'lottie-web';

Swiper.use([Navigation, Pagination, Autoplay, Lazy]);

// Import our CSS
import '../css/app-base.pcss';
import '../css/app-components.pcss';
import '../css/app-utilities.pcss';

// Lozad
window.lozadObserver = lozad('.lozad', {
    loaded: el => {
        const cropPos = el.getAttribute('data-crop-pos');
        if (cropPos) {
            const img = el.getElementsByTagName('img');
            if (img) img[0].style.objectPosition = cropPos;
        }
        el.classList.add('loaded');
    }
});

// ScrollReveal options
// Scroll Reveal
const srJustTrigger = {
    opacity: 1,
    duration: 0,
    viewFactor: .75,
    afterReveal: el => { el.classList.add('is-visible') },
    afterReset: el => { el.classList.remove('is-visible') }
};

const srTimelineImage = {
    opacity: 1,
    duration: 0,
    viewFactor: 1,
    afterReveal: el => { el.classList.add('is-visible') },
    afterReset: el => { el.classList.remove('is-visible') }
};

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const vClickOutside = await import( /* webpackChunkName: "v-click-outside" */ 'v-click-outside');
    
    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyDvFttj3dRLCVjWnSqOopy4QqI5GaEa7AQ',
            libraries: 'places'
        },
        installComponents: true
    });
    
    Vue.component('GmapCluster', GmapCluster);
    Vue.component('slide-up-down', SlideUpDown);
    
    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        
        directives: {
            clickOutside: vClickOutside.directive
        },
        
        components: {
            'mini-contact-form': () => import(/* webpackChunkName: "mini-contact-form" */ '../vue/mini-contact-form.vue'),
            'projects-map': () => import(/* webpackChunkName: "projects-map" */ '../vue/projects-map.vue'),
            'text-fx': () => import(/* webpackChunkName: "text-fx" */ '../vue/text-fx.vue')
        },
        
        data: {
            // Header video
            headerSwiper: null,
            mastheadVideoContent: null,
            headerVideoAF: null,
            mouseX: null,
            mouseY: null,
            moveDelta: null,
            overflowFactor: .35, // Set a factor of the entire window the video should be able to move around as outer bounds
            mastheadVideoDuration: 0, // in s > duration of first video
            mastheadDefaultDelay: 15, // in s > 1
            mastheadVideoSafeMargin: 1000, // in ms > 1000
            time: 0,
            videoWrapperWidth: 0,
            videoWrapperHeight: 0,
            browserIsWidescreen: true,
            
            // Navigation
            activeSegments: [],
            toggleNavFlapDone: false,
            navFlapOpenId: null,
            projectsFlapThumbnail: null,
            lastScrollPos: 0,
            hideNav: false,
            navIsFloating: false,
            
            // Mobile navigation
            mobileNavIsOpen: false,
            navSubPageOpenId: null,
            
            // Search results
            searchResultsActiveTab: 'pages',
            
            // Projects
            projectView: 'list',

            //Team
            popupId: '',
        },
        
        created() {
            window.addEventListener('resize', this.onWindowResize);
            window.addEventListener('scroll', this.onWindowScroll);
        },
        
        beforeDestroy() {
            window.removeEventListener('resize', this.onWindowResize);
            window.removeEventListener('scroll', this.onWindowScroll);
        },
        
        mounted() {
            window.lozadObserver.observe();
            // Needed for when browser reloads and scrollbar is down -> bg color of navigation
            this.onWindowScroll();
            this.initHeader();
            
            // Get project view type from URL parameter
            const urlParams = new URLSearchParams(window.location.search);
            const projectView = urlParams.get('projectview');
            if (projectView) this.projectView = projectView;
            
            // Set active navigation highlight
            const strSegments = window.location.pathname;
            this.activeSegments = strSegments.split('/').slice(1); // First segment is empty
            
            // ScrollReveal animations
            ScrollReveal().reveal('.projects-timeline .project-snippet-wrapper', srJustTrigger);
            ScrollReveal().reveal('.event-timeline .event-snippet-wrapper', srJustTrigger);
            ScrollReveal().reveal('.event-timeline .event-snippet-wrapper__image', srTimelineImage);
            
            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }
            
            // Init blocks
            this.initBlockNews();
            this.initBlockStatistics();
        },
        
        watch: {
            navFlapOpenId: function (id) {
                if (id) {
                    this.pauseHeader();
                    
                    // Toon pijltje met enige vertraging bij openen (anders zichtbaar achter flap animatie)
                    setTimeout(() => {
                        this.toggleNavFlapDone = true;
                    }, 200);
                } else {
                    this.playHeader();
                    this.toggleNavFlapDone = false;
                }
            }
        },
        
        methods: {
            onWindowResize() {
                this.initHeaderVideos();
                if (window.innerWidth >= 1024 && this.popupId === '' ) enableBodyScroll(this.$refs.navMobileContent);
                if (window.innerWidth < 1024 && this.mobileNavIsOpen) disableBodyScroll(this.$refs.navMobileContent);
            },
            
            onWindowScroll() {
                const isScrollingDown = window.pageYOffset > this.lastScrollPos;
                this.navIsFloating = window.pageYOffset > 75;
                if (!this.mobileNavIsOpen) this.hideNav = isScrollingDown && (window.pageYOffset > 75); // 75 = Height header on mobile
                if (this.hideNav) this.navFlapOpenId = null; // Close flaps permanently when menu is hidden else they re-appear when scrolling up which is weird
                this.lastScrollPos = window.pageYOffset;
            },

            initHeader() {
                // Only if not on mobile device (window.isMobile is set in the head.twig)
                if (!window.isMobile) this.initHeaderVideos();
                
                const blockEl = document.querySelectorAll('.masthead');
                for (let block of blockEl) {
                    let swipeEl = (block.getElementsByClassName('swiper-container').length) ? block.getElementsByClassName('swiper-container')[0] : false;
                    if (swipeEl) {
                        this.headerSwiper = new Swiper(swipeEl, {
                            spaceBetween: 0,
                            loop: true,
                            centeredSlides: true,
                            slidesPerView: 1,
                            speed: 1,  // Cannot be 0 -> autoplay won't work
                            autoplay: {
                                delay: (this.mastheadVideoDuration) * 1000 - this.mastheadVideoSafeMargin, // Move just before the video is finished and reset which looks nicer
                                disableOnInteraction: false
                            },
                            pagination: {
                                el: '.swiper-pagination',
                                type: 'fraction',
                                renderFraction: (currentClass, totalClass) => {
                                    return `<div class="flex items-baseline"><span class="${ currentClass }"></span><hr class="swiper-pagination__devider"><span class="${ totalClass }"></span></div>`;
                                }
                            },
                            on: {
                                // Set the autoplay delay equal to the duration of the next video (if any) so paused delays get reset also.
                                slideChangeTransitionStart: swiper => {
                                    swiper.params.autoplay.delay = this.getNextVideoDuration() * 1000 - this.mastheadVideoSafeMargin;
                                },
                                
                                /* This gets executed when swiper is initialized because of runCallbacksOnInit = true
                                 * so we don't need initCurrentVideo in mounted()
                                 */
                                slideChangeTransitionEnd: swiper => {
                                    // Reset current video slide. We don't pause the video because the autoplay delay becomes out of sync and that doesn't look nice
                                    this.stopHeader();
                                    
                                    // Init the next video slide
                                    this.initCurrentVideo();
                                }
                            }
                        });
                    }
                }
            },
            
            getNextVideoDuration() {
                const nextVideo = document.querySelector('.masthead .swiper-slide-next .masthead__video__content video');
                
                if (nextVideo && nextVideo.readyState > 0) {
                    return Math.ceil(nextVideo.duration);
                }
                
                return this.mastheadDefaultDelay;
            },
            
            initHeaderVideos() {
                const headerVideoContents = document.querySelectorAll('.masthead .swiper-slide .masthead__video__content')
                
                // Set correct size for all video sliders
                for (const [i, videoContent] of headerVideoContents.entries()) {
                    const video = videoContent.getElementsByTagName("video")[0];
                    
                    // Set video content wrapper to the correct width and height
                    this.videoWrapperWidth = window.innerWidth * (1 + this.overflowFactor);
                    this.videoWrapperHeight = window.innerHeight * (1 + this.overflowFactor);
                    videoContent.style.width = this.videoWrapperWidth + 'px';
                    videoContent.style.height = this.videoWrapperHeight + 'px';
                    
                    /* Manual object-fit functionality -> so we have more control over the zoom factor.
                     * Object fit doesn't seem to correspond 100% with this calculation, but we keep it as a fallback
                     */
                    if (video) {
                        // Set initial duration of first video
                        if (i === 0) {
                            video.onloadedmetadata = (event) => {
                                this.mastheadVideoDuration = event.target.duration;
                            }
                        }
                        
                        this.browserIsWidescreen = window.innerWidth / window.innerHeight > 1.777777778; // 16/9
                        if (!this.browserIsWidescreen) {
                            video.style.height = this.videoWrapperHeight + 'px';
                            video.setAttribute('height', this.videoWrapperHeight + 'px');
                            video.setAttribute('width', 'auto');
                        } else {
                            video.style.width = this.videoWrapperWidth + 'px';
                            video.setAttribute('width', this.videoWrapperWidth + 'px');
                            video.setAttribute('height', 'auto');
                        }
                    }
                    
                    // Calculate and set the inset to center the video content wrapper
                    const insetX = window.innerWidth * -this.overflowFactor / 2;
                    const insetY = window.innerHeight * -this.overflowFactor / 2;
                    videoContent.style.top = `${ insetY }px`;
                    videoContent.style.left = `${ insetX }px`;
                }
            },
            
            initCurrentVideo() {
                this.mastheadVideoContent = document.querySelector('.masthead .swiper-slide-active .masthead__video__content');
                
                // Only if not on mobile device (window.isMobile is set in the head.twig)
                if (!window.isMobile) {
                    cancelAnimationFrame(this.headerVideoAF);
                    
                    // Start tracking
                    this.trackVideo();
                    
                    // Track mouse movement on the active slide
                    if (this.mastheadVideoContent) {
                        // React on mouse movement by using a AnimationFrame to debounce the transform
                        this.mastheadVideoContent.addEventListener('mousemove', this.initMouseMovement);
                        
                        this.mastheadVideoContent.addEventListener('mouseleave', event => {
                            cancelAnimationFrame(this.headerVideoAF);
                        });
                        
                        this.headerVideoAF = requestAnimationFrame(this.lookAroundVideo);
                    }
                }
            },
            
            trackVideo() {
                if (this.mastheadVideoContent) {
                    // Get the current video
                    const video = this.mastheadVideoContent.querySelector('video');
                    if (video) video.play(); // We paused it on slideChangeTransitionStart slide transition
                    
                    // Get the current hotspot
                    const hotspot = this.mastheadVideoContent.querySelector('.masthead__info-panel');
                    const hotspotText = hotspot.querySelector('.masthead__info-panel__content__slide');
                    const hotspotOverlay = this.mastheadVideoContent.querySelector('.masthead__video__overlay');
                    
                    if (hotspot) {
                        // Get the hotspot starting position
                        const initialPosition = hotspot.dataset['t-0'];
                        if (initialPosition) {
                            const position = initialPosition.split(',');
                            
                            // Apply the correct position
                            hotspot.style.left = this.getCorrectedPosition(position)[0];
                            hotspot.style.top = this.getCorrectedPosition(position)[1];
                        }
                        
                        // Show the hotspot immediately when there is no video
                        if (!video) {
                            hotspot.style.opacity = 1;
                            hotspot.style.visibility = 'visible';
                        }
                        
                        // Add eventListeners for hover (@mouseover in HTML doesn't get renewed when another video slide is shown)
                        hotspot.addEventListener('mouseover', event => {
                            // Pause the header
                            this.pauseHeader();
                            
                            /* Toggle closed/show classes.
                             * We can't use Vue in the swiper because the swiper doesn't duplicate vue code
                             * inside duplicated slides when loop function is enabled.
                             */
                            if (hotspotText) hotspotText.classList.remove('closed');
                            if (hotspotOverlay) hotspotOverlay.classList.add('show');
                            
                            /* The CSS transition property has no pause option.
                             * So we get the current position and apply it while removing the transition enabling class
                             */
                            const computedStyle = window.getComputedStyle(hotspot);
                            hotspot.style.left = computedStyle.getPropertyValue('left');
                            hotspot.style.top = computedStyle.getPropertyValue('top');
                            hotspot.classList.remove('animate');
                        });
                        
                        hotspot.addEventListener('mouseout', event => {
                            // Only when no flaps are open (else we want to keep the state as is)
                            if (!this.navFlapOpenId) {
                                // Play the header
                                this.playHeader();
                                
                                // Reset hovered visual changes
                                if (hotspotOverlay) hotspotOverlay.classList.remove('show');
                                
                                // Enable transitions again
                                hotspot.classList.add('animate');
                            }
                            
                            // Reset hovered visual changes
                            if (hotspotText) hotspotText.classList.add('closed');
                        });
                    }
                    
                    if (video && hotspot) {
                        // Get the duration of the video (default set in data properties)
                        // video loadedmetadata event listeners fired already for next videos so cannot be used here
                        if (video.readyState > 0) {
                            this.mastheadVideoDuration = Math.ceil(video.duration); // 14.98s = 15s
                        }
                        
                        let lastTime = 0;
                        video.ontimeupdate = event => {
                            // Get the current playing time of the video
                            this.time = Math.floor(video.currentTime);
                            
                            /* Performance optimisation:
                             * Because ontimeupdate fires in irregular intervals multiple times per second
                             * we limit this to every second as we only have positional data per second.
                             */
                            if (this.time !== lastTime) {
                                // Show the hotspot after the video started or hide the hotspot before the video ends
                                hotspot.style.opacity = this.time > 1 ? 1 : 0;
                                hotspot.style.visibility = this.time > 1 ? 'visible' : 'hidden';
                                if (this.time === this.mastheadVideoDuration - 1) hotspot.style.opacity = 0;
                                
                                /* Get the position for the next second, not the current second
                                 * as we need to start animating towards the next position.
                                 */
                                const nextPosition = hotspot.dataset[`t-${ this.time + 1 }`];
                                // If a next position can be found and we're not freezing the hotspot
                                if (nextPosition && hotspot.classList.contains('animate')) {
                                    const position = nextPosition.split(',');
                                    
                                    // Apply the correct position
                                    hotspot.style.left = this.getCorrectedPosition(position)[0];
                                    hotspot.style.top = this.getCorrectedPosition(position)[1];
                                }
                                
                                lastTime = this.time;
                            }
                        };
                    }
                }
            },
            
            getCorrectedPosition(position) {
                // Set the original position in percentages
                let left = position[0] + '%';
                let top = position[1] + '%';
                
                /* Calculate the object-fit correction:
                 * The position markers are calculated as a percentage on the original video (4K video).
                 * But the top and left properties are set from the (0, 0) point of the videoContent element.
                 * The video is actually wider or higher (depending on the aspect ratio) than its wrapper.
                 * So we need to compensate that (0, 0) point is more to the right or to the bottom..
                 */
                if (this.browserIsWidescreen) {
                    top = this.getCorrectedTopPosition(position[1]);
                } else {
                    left = this.getCorrectedLeftPosition(position[0]);
                }
                
                return [left, top];
            },
            
            getCorrectedTopPosition(marker) {
                /* The width is set equal to the width of the videoContent element and the height is set to auto (which returns 0).
                 * So we calculate the actual height of the video:
                 */
                const videoHeight = this.videoWrapperWidth / 16 * 9; // in px
                // We convert the original top position from percentage to pixels
                const origTop = videoHeight * marker / 100;
                // We calculate how much larger in height the video is than the videoContent element and take half of it (top part only)
                const videoHeightCutoff = (videoHeight - this.videoWrapperHeight) / 2; // in px
                // The new top position is the original top position minus the cutoff in pixels
                return origTop - videoHeightCutoff + 'px';
            },
            
            getCorrectedLeftPosition(marker) {
                const videoWidth = this.videoWrapperHeight / 9 * 16; // in px
                const origLeft = videoWidth * marker / 100;
                const videoWidthCutoff = (videoWidth - this.videoWrapperWidth) / 2; // in px
                return origLeft - videoWidthCutoff + 'px';
            },
            
            initMouseMovement(e) {
                this.mouseX = this.trailingX = e.clientX;
                this.mouseY = this.trailingY = e.clientY;
                
                this.headerVideoAF = requestAnimationFrame(this.lookAroundVideo);
            },
            
            lookAroundVideo() {
                this.mastheadVideoContent.style.transform = `translate(${ this.calcVideoMove('x') }px, ${ this.calcVideoMove('y') }px)`;
            },
            
            calcVideoMove(axis) {
                const windowSize = axis === 'x' ? window.innerWidth : window.innerHeight;
                const currentMousePos = axis === 'x' ? this.mouseX : this.mouseY;
                
                // Bereken de horizontale muisafstand als een percentage van de gehele viewport breedte -> bv. 35%
                const pctMouseScreen = Math.floor(100 / windowSize * currentMousePos);
                
                /* Het volledige scherm gaat van 100% tot -100% in onze redenering.
                 * Dus 100 min tweemaal pctMouseXScreen geeft de procentuele afstand over een half scherm.
                 * -> bv. 35% wordt 70% van een half scherm
                 */
                const pctMouse = 100 - pctMouseScreen * 2;
                
                // Bereken de overlapping van de video langs een enkele zijde van het scherm
                const inset = windowSize * this.overflowFactor / 2;
                
                // De afstand die de video moet bewegen is het percentage van de afgelegde afstand over een half scherm van de inset
                return inset * pctMouse / 100;
            },
            
            toggleNavFlap(id) {
                // Reset arrow when clicking from link to link
                this.toggleNavFlapDone = false;
                this.navFlapOpenId = this.navFlapOpenId === id ? null : id;
            },
            
            pauseHeader() {
                if (this.headerSwiper) {
                    // Pause swiper autoplay
                    this.headerSwiper.autoplay.stop();
                    
                    // Disable swiping
                    this.headerSwiper.detachEvents();
                }
                
                if (this.mastheadVideoContent) {
                    const video = this.mastheadVideoContent.querySelector('video');
                    if (video) {
                        video.pause();
                    }
                    this.mastheadVideoContent.removeEventListener('mousemove', this.initMouseMovement);
                }
            },
            
            playHeader() {
                if (this.headerSwiper) {
                    // Restart swiper autoplay only by time remaining of the video minus safe margin (in ms)
                    this.headerSwiper.params.autoplay.delay = (this.mastheadVideoDuration - this.time) * 1000 - this.mastheadVideoSafeMargin;
                    this.headerSwiper.autoplay.start();
                    
                    // Re-enable swiping
                    this.headerSwiper.attachEvents();
                }
                
                if (this.mastheadVideoContent) {
                    const video = this.mastheadVideoContent.querySelector('video');
                    if (video) {
                        video.play();
                    }
                    this.mastheadVideoContent.addEventListener('mousemove', this.initMouseMovement);
                }
            },
            
            stopHeader() {
                if (this.mastheadVideoContent) {
                    const video = this.mastheadVideoContent.querySelector('video');
                    if (video) {
                        video.pause();
                        video.currentTime = 0;
                    }
                }
            },
            
            closeNavFlap(event) {
                const el = event.target.closest('.nav-main__pop-up');
                if (!el) {
                    this.navFlapOpenId = null;
                    setTimeout(() => {
                        this.toggleNavFlapDone = false;
                    }, 200);
                }
            },
            
            toggleMobileNav() {
                this.mobileNavIsOpen = !this.mobileNavIsOpen;
                this.mobileNavIsOpen ? disableBodyScroll(this.$refs.navMobileContent) : enableBodyScroll(this.$refs.navMobileContent);
            },
            
            clearInput(event) {
                event.target.value = '';
            },
            
            openSubNav(id) {
                this.navSubPageOpenId = id;
                // Stop scrolling mobile nav root
                //enableBodyScroll(this.$refs.navMobileContent);
                // Start scrolling subpage
                //disableBodyScroll(this.$refs[id]);
            },
            
            closeSubNav() {
                this.navSubPageOpenId = null;
            },
            
            setProjectView(view) {
                this.projectView = view;
                if (view === 'timeline') ScrollReveal().reveal('.projects-timeline .project-snippet-wrapper', srJustTrigger);
            },
            
            initBlockNews() {
                const blockEl = document.querySelectorAll('.block-news-carousel');
                for (let block of blockEl) {
                    let swipeEl = (block.getElementsByClassName('swiper-container').length) ? block.getElementsByClassName('swiper-container')[0] : false;
                    if (swipeEl) {
                        const btnNext = (block.getElementsByClassName('swiper-next').length) ? block.getElementsByClassName('swiper-next')[0] : false;
                        const btnPrev = (block.getElementsByClassName('swiper-prev').length) ? block.getElementsByClassName('swiper-prev')[0] : false;
                        const swiperPagination = (block.getElementsByClassName('swiper-slides').length) ? block.getElementsByClassName('swiper-slides')[0] : false;
                        const slidesCount = block.dataset['slides-count'];
                        
                        new Swiper(swipeEl, {
                            loop: slidesCount > 3,
                            roundLengths: true,
                            watchOverflow: true,
                            centeredSlides: slidesCount > 3,
                            slidesPerView: 1.2,
                            
                            pagination: {
                                el: swiperPagination,
                                type: 'fraction',
                                formatFractionCurrent: number => number < 10 ? '0' + number : number,
                                formatFractionTotal: number => number < 10 ? '0' + number : number,
                                renderFraction: (currentClass, totalClass) => `<span class="${ currentClass } numbers"></span><span class="mx-1">/</span><span class="${ totalClass } numbers"></span>`
                            },
                            
                            navigation: {
                                nextEl: btnNext,
                                prevEl: btnPrev
                            },
                            
                            breakpoints: {
                                // when window width is >= 768px
                                768: {
                                    slidesPerView: 2.2
                                },
                                1000: {
                                    slidesPerView: 2.5
                                },
                                1280: {
                                    slidesPerView: 3.5
                                },
                                1920: {
                                    slidesPerView: 5.5
                                }
                            }
                        });
                    }
                }
            },
    
            initBlockStatistics() {
                const blockEl = document.querySelectorAll('.block-statistics');
                for (let block of blockEl) {
                    let svgAnimContainers = Array.from(block.querySelectorAll('.snippet__thumbnail__svg'));
                    svgAnimContainers.forEach(container => {
                        let name = container.dataset.animName;
                        console.log(container);
                        this.loadThumbnailAnimation(name, container);
                    });
                }
            },
    
            loadThumbnailAnimation(name, container){
                lottie.loadAnimation({
                    container: container, // the dom element that will contain the animation
                    renderer: 'svg', // canvas
                    loop: true,
                    autoplay: true,
                    path: '/assets/svg-anims/' + name + '.json' // the path to the animation json
                });
            },

            toggleTeamPopUp(id) {
                this.popupId = this.popupId === id ? '' : id;
                const popUp = document.getElementById('pop-up-' + id);
                this.popupId !== '' ? disableBodyScroll(popUp) : enableBodyScroll(popUp);
            }
        },
    });
    
    return vm;
};

// Execute async function
main().then((vm) => {
    // Add visible class to html
    document.documentElement.classList.add('loaded');
    document.documentElement.classList.remove('loading');
    // IE11 object-fit polyfill
    objectFitImages();
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
